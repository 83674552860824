<template>
  <table class="table table-hover table-striped">
    <thead>
    <tr>
<!--      <th>Local Policy ID</th>-->
      <th>Name</th>
      <th v-if="showProgrammeFields">Programme</th>
      <th>Country</th>
      <th>Class</th>
      <th>Policy Period</th>
      <th>Live</th>
      <th v-if="showHandlersInLists()">Handler</th>
      <th v-if="showHandlersInLists()">Manager</th>
      <th v-if="showPremiumDataInLists()">Premium</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(localPolicy, index) in localPolicies" :key="index">
<!--      <td>{{ localPolicy.local_policy_id }}</td>-->
      <td>
        <router-link
            v-b-tooltip:hover title="View Local Policy"
            :to="{name: 'Local Policy Overview', params: {programmeId: localPolicy.master_policy_id, localPolicyId: localPolicy.local_policy_id}}"
            class="name mb-0 text-sm"
        >
          {{ localPolicy.local_policy_name }}
        </router-link>
      </td>
      <td v-if="showProgrammeFields">
        <router-link
          v-b-tooltip:hover title="View Programme"
          :to="{name: 'Programme Overview', params: {programmeId: localPolicy.master_policy_id}}"
          >
        {{ programmeName(localPolicy.master_policy_id) }}
        </router-link>
      </td>
      <td>{{ localPolicy.country.country_name }}</td>
      <td>{{ programmeClass(localPolicy.master_policy_id) }}</td>
      <td>{{ $options.filters.formatDateUK(localPolicy.date_inception) + ' - ' + $options.filters.formatDateUK(localPolicy.date_expiry) }}
      <td><live-badge :inception="localPolicy.date_inception" :expiry="localPolicy.date_expiry" /></td>
      <td v-if="showHandlersInLists()">{{ contactName(localPolicy.primary_handler_company_contact) }}</td>
      <td v-if="showHandlersInLists()">{{ contactName(localPolicy.primary_manager_company_contact) }}</td>
      <td v-if="showPremiumDataInLists()">
        <div class="row row-xs">
          <div style="width: 40px;" v-if="localPolicy.currency">
            {{ programmeCurrency(localPolicy.master_policy_id) }}
          </div>
          {{ formatThousandsMixin(calculateTotalPremium(localPolicy.lines)) }}
        </div>
      </td>
      <td>
        <status-badge :status-mask="localPolicy.status" />
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
import PolicyMixin from "@clients/src/mixins/policy.mixin";
import LiveBadge from "@common/src/components/LiveBadge";
import StatusBadge from "@common/src/components/StatusBadge";
import FeaturesMixin from "@clients/src/mixins/features.mixin";
export default {
  name: "LocalPolicyTable",
  components: {
    LiveBadge,
    StatusBadge
  },
  props: {
    localPolicies: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [PolicyMixin, FeaturesMixin],
  methods: {
    contactName(contact) {
      if (contact === undefined) {
        return ' - ';
      }

      return contact.forename + ' ' + contact.surname;
    },
    programme(programmeId) {
      return this.$store.getters.programmes.find(programme => programme.id == programmeId);
    },
    programmeClass(programmeId) {
      const programme = this.programme(programmeId);
      return programme?.class ?? '';
    },
    programmeName(programmeId) {
      const programme = this.programme(programmeId);
      return (programme!==undefined) ? programme.name : ' - ';
    },
    programmeCurrency(programmeId) {
      return this.programme(programmeId)?.currencyCode;
    }
  },
  computed: {
    showProgrammeFields() {
      return this.$route.name !== 'Programme Overview';
    }
  }
}
</script>
