<template>
  <div
      id="map-canvas"
      class="map-canvas"
      data-lat="40.748817"
      data-lng="-73.985428"
  >
  </div>
</template>
<script>
export default {
  name: "HeatMap",
  props: {
    rawData: {
      type: Array,
    },
  },
  data: () => ({
    countryData: [],
  }),
  methods: {
    drawRegionsMap() {
      let google = window.google;

      if (!this.rawData) {
        // Placeholder data
        this.countryData = google.visualization.arrayToDataTable([
          ['Country', 'USD'],
          ['Germany', -5400],
        ]);
      } else {
        this.countryData = google.visualization.arrayToDataTable(this.rawData);
      }
      if (!this.map) {
        this.map = new google.visualization.GeoChart(document.getElementById('map-canvas'));
      }

      const mapOptions = {
        colorAxis: {
          minValue: this.minValue,
          maxValue: this.maxValue,
          values: [this.minValue, this.maxValue],
          colors: ['#' + process.env.VUE_APP_DONUT_RED, '#' + process.env.VUE_APP_DONUT_GREEN],
        },
        // backgroundColor: '#232388',
        datalessRegionColor: '#f5f5f5',
        tooltip: { isHtml: true },
      }

      this.map.draw(this.countryData, mapOptions);

      // Add listeners for country clicks.
      // google.visualization.events.addListener(
      //   this.map,
      //   'select',
      //   () => {
      //     const sSelectedItem = this.map.getSelection()[0];
      //     let selectedCountry = null;
      //
      //     // If the user has clicked on a country, generate the map for selected country.
      //     if(sSelectedItem) {
      //       selectedCountry = this.countryData.getValue(sSelectedItem.row, 0);
      //       if (this.showModal) {
      //         this.showCountryBreakdownModal(selectedCountry);
      //       }
      //       this.$emit('country-selected', selectedCountry);
      //     } else {
      //       this.$emit('country-selected', '');
      //     }
      //   }
      // );
    },
    loadMap() {
      let google = window.google;
      google.charts.load('current', {
        'packages': ['geochart'],
        // TODO: Move this key to the .env file...
        'mapsApiKey': 'AIzaSyDZypgosV2MAupY4T5qfZgKTe1Md6M14TE'
      });

      google.charts.setOnLoadCallback(this.drawRegionsMap);
    },
    resizeMap() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(
          () => {
            this.drawRegionsMap();
          },
          500
      );
    }
  },
  computed: {
    minValue() {
      if (!this.rawData) {
        return 0;
      }
      let lowest = 0;
      for (let vals of this.rawData) {
        if (!isNaN(vals[1]) && vals[1] < lowest) {
          lowest = vals[1];
        }
      }
      return lowest;
    },
    maxValue() {
      if (!this.rawData) {
        return 0;
      }
      let lowest = -999999999999;
      for (let vals of this.rawData) {
        if (!isNaN(vals[1]) && vals[1] > lowest) {
          lowest = vals[1];
        }
      }
      return lowest;
    }
  },
  watch: {
    rawData(newData) {
      if (!newData) {
        return;
      }
      this.resizeMap();
    }
  },
  created() {
    if (!this.rawData) {
      return;
    }
    window.addEventListener("resize", this.resizeMap);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeMap);
  },
  mounted() {
    if (!this.rawData) {
      return;
    }
    this.loadMap();
  }
}
</script>
