<template>
  <div class="programme-key-contacts">
    <div class="row">
      <div class="col">
        <table class="table" id="contactsTable">
          <tbody>
          <tr v-for="(contact, index) in contacts" :key="index">
            <td>
              <span>
                <strong>{{ contact.type }}</strong>
              </span>
            </td>
            <td>
              <img v-if="contact.image"
                   class="rounded-50"
                   :src="imageSourceMixin(contact.image)"
              />
              <img v-else
                   class="rounded-50"
                   :src="imageSourceMixin('user-placeholder.jpg')"
              />
            </td>
            <td>
              <span>
                {{ contact.forename + ' ' + contact.surname }}
              </span>
            </td>
            <td>
              <a v-b-tooltip:hover
                 :title="'Email ' + contact.type"
                 :href="'mailto:' + contact.email">
                {{ contact.email }}
              </a>
            </td>
            <td>
              <span>{{ contact.telephone}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PolicyContacts",
  props: {
    contacts: {
      type: Array,
      required: true
    },
  },
}
</script>

<style scoped lang="scss">
$contactImageSize: 60px;

table#contactsTable td img {
  width: $contactImageSize;
  height: $contactImageSize;
}

table#contactsTable td span, table#contactsTable td a {
  display: inline-block;
  margin-top: ($contactImageSize * 0.35);
}
</style>
