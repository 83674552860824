<template>
  <div class="gobal-overview-map">
    <div class="row">
      <div class="col">
        <div class="card shadow border-0">
          <div
              id="map-canvas"
              class="map-canvas"
              data-lat="40.748817"
              data-lng="-73.985428"
              :class="{'short-map': shortMap, 'tall-map': !shortMap}"
          >
          </div>
        </div>
      </div>
    </div>
    <b-modal
        id="modal-country-breakdown"
        hide-footer ref="modal-country-breakdown"
    >
      <template v-slot:modal-header>
        <h2><i class="fa fa-table"></i>&nbsp;{{ selectedCountry }} - Breakdown</h2>
      </template>

      <h4>Premium Details</h4>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Local Policy</th>
          <th>Currency</th>
          <th>Amount</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in selectedCountryData" :key="row.key">
          <td>{{ row.title }}</td>
          <td>{{ row.currency}}</td>
          <td>{{ row.amount }}</td>
        </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'GlobalOverviewMap',
  props: {
    shortMap: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    selectedCountry: 'UK',
    selectedLineOfBusiness: 'Property',
    selectedCountryData: [],
    countryData: false,
    map: false,
    resizeTimeout: false,
  }),
  methods: {
    drawRegionsMap() {
      let google = window.google;

      // There is no need to initialise this mocked data and the map object if they
      // already exist, as they are fixed at the moment... Will be loading from
      // the API in the future
      if (!this.countryData) {
        this.countryData = google.visualization.arrayToDataTable([
          ['Country', 'Profitability (USD)'],
          ['Germany', -5400],
          ['United States', 9100000],
          ['Brazil', -120000],
          ['Canada', 850000],
          ['France', 123456],
          ['Russia', 654321]
        ]);
      }
      if (!this.map) {
        this.map = new google.visualization.GeoChart(document.getElementById('map-canvas'));
      }

      const mapOptions = {
        colorAxis: {
          minValue: -120000,
          maxValue: 9100000,
          values: [-1, 1],
          colors: ['#d22828', '#2dce50'],
        },
        legend: false,
        // backgroundColor: '#232388',
        datalessRegionColor: '#eeeeee',
        tooltip: { isHtml: true },
      }

      this.map.draw(this.countryData, mapOptions);

      // Add listeners for country clicks.
      google.visualization.events.addListener(
          this.map,
          'select',
          () => {
            const sSelectedItem = this.map.getSelection()[0];
            let selectedCountry = null;

            // If the user has clicked on a country, generate the map for selected country.
            if(sSelectedItem) {
              selectedCountry = this.countryData.getValue(sSelectedItem.row, 0);
              if (this.showModal) {
                this.showCountryBreakdownModal(selectedCountry);
              }
              this.$emit('country-selected', selectedCountry);
            } else {
              this.$emit('country-selected', '');
            }
          });
    },
    loadMap() {
      let google = window.google;
      google.charts.load('current', {
        'packages': ['geochart'],
        'mapsApiKey': 'AIzaSyDZypgosV2MAupY4T5qfZgKTe1Md6M14TE'
      });

      google.charts.setOnLoadCallback(this.drawRegionsMap);
    },
    showCountryBreakdownModal(country) {
      this.selectedCountry = country;
      // Generate country data
      this.selectedCountryData = [];
      let newData = [];
      for (let c = 0; c < (2 + (Math.random() * 10)); c++) {
        newData.push({
          title: country + (Math.random() < 0.5 ? ' ' + this.selectedLineOfBusiness : '') + ' ' + String.fromCharCode(65 + c),
          currency: 'USD',
          amount: parseFloat((10000 +(Math.random() * 10000000)).toPrecision(2)).toLocaleString()
        });
      }
      this.selectedCountryData = newData;
      this.$root.$emit("bv::show::modal", "modal-country-breakdown");
    },
    resizeMap() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(
          () => {
            this.drawRegionsMap();
          },
          500
      );
    }
  },
  created() {
    window.addEventListener("resize", this.resizeMap);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeMap);
  },
  mounted() {
    this.loadMap();
  }
}
</script>
<style scoped>
#modal-country-breakdown___BV_modal_backdrop_ {
  background-color: rgba(0,0,0,0.3);
}

.card#form-card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card#form-card select.form-control {
  padding: 5px 7px;
  line-height: 1.2;
  height: calc(1.2em + 1.2rem + 2px);
}

.card#form-card .row.form-group:last-of-type {
  margin-bottom: 0;
}

.tall-map {
  height: 600px;
}

.short-map {
  height: 300px;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

#map-canvas > div > div > div {
  overflow: hidden;
  border-radius: 6px;
}
</style>
