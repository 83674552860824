<template>
  <div>
    <table v-if="tableId === 'capabilities'" class="table table-hover">
      <thead>
      <tr>
        <th>&nbsp;</th>
        <th v-for="(feature, key) in tableData" v-bind:key="key">{{ key }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="font-weight-bold">
          Included
        </td>
        <td v-for="(feature, key) in tableData" v-bind:key="key" :class="{'text-muted font-italic': isEmptyValue(feature['included'])}">
          {{ handleEmptyValue(feature['included']) }}
        </td>
      </tr>
      <tr>
        <td class="font-weight-bold">
          Policy Form Applicable
        </td>
        <td v-for="(feature, key) in tableData" v-bind:key="key" :class="{'text-muted font-italic': isEmptyValue(feature['Policy Form Applicable'])}">
          {{ handleEmptyValue(feature['Policy Form Applicable']) }}
        </td>
      </tr>
      </tbody>
    </table>
    <table v-else class="table table-hover">
      <thead>
      <tr>
        <th>Name</th>
        <th>Value</th>
        <th>Additional Information</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(feature, key) in tableData" v-bind:key="key">
        <td class="font-weight-bold">
          {{ feature.name }}
        </td>
        <td :class="{'text-muted font-italic': isEmptyValue(feature.value)}">
          {{ handleEmptyValue(feature.value) }}
        </td>
        <td :class="{'text-muted font-italic': isEmptyValue(feature.additional)}">
          {{ handleEmptyValue(feature.additional) }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'CompanyWikiFeatureTable',
  props: {
    'tableData': {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array|Object,
      default: [],
    },
    'tableId': {
      type: String,
      default: '',
    }
  },
  methods: {
    handleEmptyValue(value) {
      return (typeof value === 'undefined' || !value || !(value.trim())) ? '' : value ;
    },
    isEmptyValue(value) {
      return (typeof value === 'undefined' || !value || !(value.trim()))
    }
  }
}
</script>