<template>
  <table class="table table-hover table-striped">
    <thead>
    <tr>
<!--      <th>Claim ID</th>-->
      <th v-if="showLocalPolicyFields">Unmatched</th>
      <th>Local Claim Number</th>
      <th>Claimant</th>
      <th>Programme</th>
      <th v-if="showLocalPolicyFields">Local Policy</th>
      <th>Date of Loss</th>
      <th>Date of Claim</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(claim, index) in claims" :key="index">
<!--      <td>{{ claim.claim_id }}</td>-->
      <td v-if="showLocalPolicyFields">{{ claim.flag_unmatched ? 'Yes' : 'No' }}</td>
      <td>{{ claim.local_claim_number }}</td>
      <td>{{ claim.claimant_fullname }}</td>
      <td>
        <router-link v-if="claim.local_policy!==undefined"
                     :to="{name: 'Programme Overview', params: {programmeId: claim.local_policy.master_policy_id}}"
                     v-b-tooltip:hover title="View Programme"
        >
          {{ programmeName(claim.local_policy.master_policy_id) }}
        </router-link>
        <span v-else>Unmatched</span>
      </td>
      <td v-if="showLocalPolicyFields">
        <router-link
            v-if="claim.local_policy !== undefined"
            :to="{name: 'Local Policy Overview', params: {programmeId: claim.local_policy.master_policy_id, localPolicyId: claim.local_policy_id}}"
            class="name mb-0 text-sm"
            v-b-tooltip:hover title="View Local Policy"
        >
          {{ claim.local_policy.local_policy_name }}
        </router-link>
        <span v-else>Unmatched</span>
      </td>
<!--      <td>-->
<!--        {{ claim.lines.reduce((total, line) => total + line.dec_risk_premium, 0) | thousands }}-->
<!--      </td>-->
      <td>{{ claim.date_of_loss | formatDateUK }}</td>
      <td>{{ claim.date_notified | formatDateUK }}</td>
      <td>
        <router-link
            :to="{ name: 'Claim Overview', params: { claimId: claim.claim_id } }"
            class="btn btn-primary btn-xs"
            style="padding: 4px 5px;"
        >
          <search-icon />&nbsp;View Claim
        </router-link>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
import { SearchIcon } from 'vue-feather-icons';
export default {
  name: "ClaimTable",
  components: {
    SearchIcon
  },
  props: {
    claims: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    programmeName(programmeId) {
      const programme = this.$store.getters.programmes.find(programme => programme.id == programmeId);
      return (programme!==undefined) ? programme.name : ' - ';
    }
  },
  computed: {
    showLocalPolicyFields() {
      return this.$route.name !== 'Local Policy Overview';
    }
  }
}
</script>
