<template>
  <div>
    <div>
      <!--    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">-->
      <!-- Card stats -->
      <!--    </base-header>-->
      <!--    <div class="d-sm-flex align-items-center justify-content-between mg-b-10 mg-lg-b-15 mg-xl-b-20">-->
      <!--      <div>-->
      <!--        <nav aria-label="breadcrumb">-->
      <!--          <ol class="breadcrumb breadcrumb-style1 mg-b-10">-->
      <!--            <li class="breadcrumb-item active" aria-current="page"><a href="#">Dashboard</a></li>-->
      <!--            &lt;!&ndash;            <li class="breadcrumb-item active" aria-current="page">Sales Monitoring</li>&ndash;&gt;-->
      <!--          </ol>-->
      <!--        </nav>-->
      <!--      </div>-->
      <!--      <div class="d-none d-md-block">-->
      <!--        <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><mail-icon></mail-icon> Email</button>-->
      <!--        <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><printer-icon></printer-icon> Print</button>-->
      <!--        <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><file-icon></file-icon> Generate Report</button>-->
      <!--      </div>-->
      <!--    </div>-->
      <div class="row row-xs">
        <div class="col-12">
          <!--    Current Programme Policy Status    -->
          <card>
            <!--         Title and programme dropdown   -->
            <div class="row">
              <!--             Top widgets   -->
              <div class="col-12">
                <div class="row">
                  <div class="col-4">
                    <!--          Programme selection dropdown    -->
                    <div class="row">
                      <div class="col text-center">
                        <div class="form-group mt-2">
                          <label for="selProgramme" class="text-sm text-muted">
                            Select Programme
                            <select id="selProgramme" class="form-control" @input="programmeId = $event.target.value">
                              <option
                                  v-for="row in $store.getters.programmes"
                                  :value="row.id"
                                  :key="row.id"
                                  :selected="programmeId==row.id"
                              >
                                {{ row.name + ' - ' + $options.filters.formatDateUK(row.inception) }}
                              </option>
                            </select>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <router-link v-if="programme" :to="{name:'Programme Overview', params: {programmeId: selectedProgramme}}">
                      <h3 class="mt-3 mb-0 text-center">{{ programme ? programme.name : '' }}</h3>
                      <h6 class="text-center">{{ programme.class }}</h6>
                      <h6 class="text-center">{{ $options.filters.formatDateUK(programme.inception) + ' - ' + $options.filters.formatDateUK(programme.expiry) }}</h6>
                    </router-link>
                  </div>
                  <div class="col-4"></div>
                </div>
                <div class="programme-metrics mt-3">
                  <h4 class="mb-3 text-center">Programme Metrics</h4>
                  <h5 class="mb-3 text-center text-muted" v-if="$store.getters.loadingProgrammes">
                    <font-awesome-icon :icon="faSpinner" spin />&nbsp;Getting Programmes
                  </h5>
                  <policy-donuts
                      :timeliness="timelinessCounts"
                      :issued="issuanceCounts"
                      :premium="premiumReceiptCounts"
                      :loading-data="$store.getters.loadingProgrammes"
                  ></policy-donuts>
                </div>
                <div v-cloak class="hide row row-xs mt-4" v-if="programme">
                  <div class="col-md-6 col-lg-3">
                    <h5>Programme Total Premium</h5>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    {{ this.formatThousandsMixin(programme.premium) }} {{ programme.currencyCode }}
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <h5>Countries Covered</h5>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    {{ programme.countriesCovered }}
                  </div>
                </div>
                <h4 class="my-3 text-center">Key Contacts</h4>
                <policy-contacts class="mt-3" :contacts="programmeHandlers" />
                <div v-if="widgetIndex===-1">
                  <h3 class="mb-4 text-center">Programme Overview</h3>
                  <policy-timeline
                      :programme-id="selectedProgramme"
                  ></policy-timeline>
                </div>
                <div v-else-if="widgetIndex===-1">
                  <h3 class="text-center">Global Overview</h3>
                  <global-overview-map
                      short-map
                      :show-modal="false"
                      @country-selected="countrySelected"
                  ></global-overview-map>
                </div>
              </div>
              <div class="col-xl-6 mt-6">
                <stats-list></stats-list>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div v-if="showPremiumDataInLists()" class="row row-xs">
        <div class="col-sm-12 col-lg-4 mg-t-10">
          <card>
            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Total Premuim</h6>
            <div class="d-flex d-lg-block d-xl-flex align-items-end">
              <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{ totalPremium }}</h3>
            </div>
          </card>
        </div>
        <div class="col-sm-12 col-lg-4 mg-t-10">
          <card>
            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Total Claims</h6>
            <div class="d-flex d-lg-block d-xl-flex align-items-end">
              <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{ totalClaims }}</h3>
            </div>
          </card>
        </div>
        <div class="col-sm-12 col-lg-4 mg-t-10">
          <card class="ht-100p">
            <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Inquiries</h6>
            <div class="d-flex d-lg-block d-xl-flex align-items-end">
              <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{ totalInquiries }}</h3>
            </div>
          </card>
        </div>
      </div>
      <div class="row row-xs hide">
        <div class="col-12 mg-t-10">
          <!--    Live Programmes    -->
          <card class="ht-100p" body-classes="pd-20">
            <div class="row">
              <!--           Live Programmes - bottom left   -->
              <div class="col-12">
                <h6>Live Programmes</h6>
                <programme-table v-if="$store.getters?.activeProgrammes?.length" :programmes="$store.getters.activeProgrammes" />
                <span v-else>No active programmes</span>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <div class="row row-xs mt-2">
      <div class="col">
        <card>
          <!-- Top row in card -->
          <div class="row justify-content-between">
            <!--           Programme title and ID   -->
            <div class="col-6">
              <h3 class="mb-0 card-title">
                Programme
              </h3>
              <em class="text-muted">{{ programme.name }} <small>- ID {{ programmeId }}</small></em>
            </div>
            <!--           Programme selection dropdown   -->
            <div class="col-6 hide">
              <div class="form-group">

                <div class="col text-center">
                <label class="text-sm text-muted">
                  Select Programme
                  <select class="form-control" @input="$router.push({name: 'Programme Overview', params: {programmeId: $event.target.value}})">
                    <option
                        v-for="row in $store.getters.programmes"
                        :value="row.id"
                        :key="row.id"
                        :selected="programmeId==row.id"
                    >
                      {{ row.name + ' - ' + $options.filters.formatDateUK(row.inception) }}
                    </option>
                  </select>
                </label>
                  </div>


              </div>
            </div>
          </div>
          <card style="background-color: rgba(165,165,255,0.15);" class="mb-2" :shadow="false">
            <!-- MPol-level information -->
            <div class="row row-xs mt-2">
              <data-row>
                <template slot="name">Programme Name</template>
                <template slot="value">
                  <b-overlay :show="$store.getters.loadingProgrammes" variant="light" rounded="lg" spinner-small>
                    {{ programme.name || ' - '}}
                  </b-overlay>
                </template>
              </data-row>
              <data-row>
                <template slot="name">Client</template>
                <template slot="value">
                  <b-overlay :show="$store.getters.loadingProgrammes" variant="light" rounded="lg" spinner-small>
                    {{ $store.getters.client?.client_name ?? programme?.client_name }}
                  </b-overlay>
                </template>
              </data-row>
            </div>
            <div class="row row-xs my-2">
              <data-row>
                <template slot="name">Class</template>
                <template slot="value">{{programme.class}}</template>
              </data-row>
              <data-row>
                <template slot="name">Local Policies</template>
                <template slot="value">{{programme.lpolCount}}</template>
              </data-row>
            </div>
            <div class="row row-xs my-2">
              <data-row>
                <template slot="name">Currency</template>
                <template slot="value">{{programme.currencyCode}}</template>
              </data-row>
              <data-row>
                <template slot="name">Inception</template>
                <template slot="value" v-if="!$store.getters.loadingProgrammes">{{programme.inception | formatDateUK}}</template>
              </data-row>
            </div>
            <div class="row row-xs">
              <data-row>
                <template slot="name">Countries Covered</template>
                <template slot="value">{{ programme.countriesCovered }}</template>
              </data-row>
              <data-row>
                <template slot="name">Expiry</template>
                <template slot="value" v-if="!$store.getters.loadingProgrammes">{{programme.expiry | formatDateUK}}</template>
              </data-row>
            </div>
          </card>
          <!--      Policy list query buttons    -->
          <!--         Timeline and stats row   -->
          <!--          <div class="row">-->
          <!--            <div class="col">-->
          <!--              <policy-timeline-->
          <!--                  :programme-id="id"-->
          <!--              >-->
          <!--              </policy-timeline>-->
          <!--            </div>-->
          <!--          </div>-->
        </card>
      </div>
    </div>
    <div class="row row-xs mt-2">
      <div class="col">
        <card>
          <h6>Use these buttons to filter the local policies below to the given state</h6>
          <div class="row row-xs mb-2">
            <div class="col text-center">
              <button class="btn btn-sm wd-100p ht-100p"
                      :class="{'btn-primary': filter===FILTER_ALL, 'btn-outline-secondary': filter!==FILTER_ALL}"
                      @click="filter=FILTER_ALL"
              >
                <circle-icon></circle-icon>&nbsp;Show All ({{ localPolicies?.length }})
              </button>
            </div>
            <div class="text-center">
              <chevron-right-icon style="margin-top: 55%;" />
            </div>
            <div class="col text-center">
              <button class="btn btn-sm wd-100p ht-100p"
                      :class="{'btn-primary': filter===FILTER_OUTSTANDING, 'btn-outline-secondary': filter!==FILTER_OUTSTANDING}"
                      @click="filter=FILTER_OUTSTANDING"
              >
                <alert-triangle-icon></alert-triangle-icon>&nbsp;Instructions Outstanding ({{ outstanding?.length }})
              </button>
            </div>
            <div class="text-center">
              <chevron-right-icon style="margin-top: 55%;" />
            </div>
            <div class="col text-center">
              <button class="btn btn-sm wd-100p ht-100p"
                      :class="{'btn-primary': filter===FILTER_COMPLETE, 'btn-outline-secondary': filter!==FILTER_COMPLETE}"
                      @click="filter=FILTER_COMPLETE"
              >
                <book-icon></book-icon>&nbsp;Instructions Complete ({{ instructionsComplete?.length }})
              </button>
            </div>
            <div class="text-center">
              <chevron-right-icon style="margin-top: 55%;" />
            </div>
            <div class="col text-center">
              <button class="btn btn-sm wd-100p ht-100p"
                      :class="{'btn-primary': filter===FILTER_SENT, 'btn-outline-secondary': filter!==FILTER_SENT}"
                      @click="filter=FILTER_SENT"
              >
                <send-icon></send-icon>&nbsp;Bind Order Sent ({{ bindOrderSent?.length }})
              </button>
            </div>
            <div class="text-center">
              <chevron-right-icon style="margin-top: 55%;" />
            </div>
            <div class="col text-center">
              <button class="btn btn-sm wd-100p ht-100p"
                      :class="{'btn-primary': filter===FILTER_ISSUED, 'btn-outline-secondary': filter!==FILTER_ISSUED}"
                      @click="filter=FILTER_ISSUED"
              >
                <inbox-icon></inbox-icon>&nbsp;Local Policy Issued ({{ issued?.length }})
              </button>
            </div>
            <div class="text-center">
              <chevron-right-icon style="margin-top: 55%;" />
            </div>
            <div class="col text-center">
              <button class="btn btn-sm wd-100p ht-100p"
                      :class="{'btn-primary': filter===FILTER_COLLECTED, 'btn-outline-secondary': filter!==FILTER_COLLECTED}"
                      @click="filter=FILTER_COLLECTED"
              >
                <dollar-sign-icon></dollar-sign-icon>&nbsp;Local Premium Collected ({{ premiumsCollected.length }})
              </button>
            </div>
          </div>
          <div v-if="!$store.getters.loadingLocalPolicies && filteredLocalPolicies.length > 0" class="row">
            <div class="col-12">
              <h5 class="my-3">{{filter}}</h5>
              <local-policy-table :local-policies="filteredLocalPolicies"></local-policy-table>
            </div>
          </div>
          <div v-else-if="$store.getters.loadingProgrammes" class="row mt-4">
            <div class="col text-center">
              <h5><font-awesome-icon :icon="faSpinner" spin></font-awesome-icon>&nbsp;Getting programme</h5>
            </div>
          </div>
          <div v-else-if="$store.getters.loadingLocalPolicies" class="row mt-4">
            <div class="col text-center">
              <h5><font-awesome-icon :icon="faSpinner" spin></font-awesome-icon>&nbsp;Getting local policies</h5>
            </div>
          </div>
          <div v-else-if="localPolicies.length===0" class="row mt-4">
            <div class="col text-center">
              <h5>There are no Local Policies attached to this Programme</h5>
            </div>
          </div>
          <div v-else class="row mt-4">
            <div class="col text-center">
              <h5>There are no Local Policies for that filter</h5>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div v-if="heatmapData.length > 1 && false" class="row row-xs mt-2">
      <div class="col">
        <card>
          <h5>Heatmap</h5>
          <heat-map v-if="false" :raw-data="heatmapData"/>
        </card>
      </div>
    </div>
    <div class="row row-xs mt-2">
      <div class="col">
        <card>
          <h5 class="mb-3">Key Contacts</h5>
          <policy-contacts :contacts="programmeHandlers" />
        </card>
      </div>
    </div>

    <div class="row row-xs mt-2">
      <div class="col">
        <card>
          <h5 class="mb-3">Documents</h5>
          <inquiry-documents-table-view v-if="programme.documents" :documents="programme.documents"></inquiry-documents-table-view>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  AlertTriangleIcon,
  BookIcon,
  SendIcon,
  InboxIcon,
  DollarSignIcon,
  CircleIcon,
  ChevronRightIcon
} from "vue-feather-icons";
import DataRow from "@common/src/components/DataRow";
import LocalPolicyTable from "@common/src/components/LocalPolicyTable";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import PolicyContacts from "@common/src/components/PolicyContacts";
import HeatMap from "@common/src/components/HeatMap";

import PolicyTimeline from "@common/src/components/PolicyTimeline";
import PolicyDonuts from "@common/src/components/PolicyDonuts";
import GlobalOverviewMap from "@common/src/components/GlobalOverviewMap";
import StatsList from "@common/src/components/StatsList";
import ProgrammeTable from "@common/src/components/ProgrammeTable";

import InquiryDocumentsTableView from "@underwriters/src/components/InquiryDocumentsTableView";
import AddFiles from "@underwriters/src/components/AddFiles";

import FeaturesMixin from '@clients/src/mixins/features.mixin';

import * as chartConfigs from '@common/src/components/Charts/config';

export default {
  name: 'ProgrammeOverview',
  components: {
    HeatMap,
    PolicyContacts,
    LocalPolicyTable,
    DataRow,
    AlertTriangleIcon,
    BookIcon,
    SendIcon,
    InboxIcon,
    DollarSignIcon,
    CircleIcon,
    ChevronRightIcon,
    GlobalOverviewMap,
    PolicyDonuts,
    PolicyTimeline,
    StatsList,
    ProgrammeTable,
    InquiryDocumentsTableView,
    AddFiles
  },
  props: {
    programmeId: {
      type: [Number, String],
      default: 0
    },
  },
  mixins: [FeaturesMixin],
  data: () => ({
    bigLineChart: {
      allData: [
        [0, 20, 10, 30, 15, 40, 20, 60, 60],
        [0, 20, 5, 25, 10, 30, 15, 40, 40]
      ],
      activeIndex: 0,
      chartData: {
        datasets: [],
        labels: [],
      },
      extraOptions: chartConfigs.blueChartOptions,
    },
    redBarChart: {
      chartData: {
        labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{
          label: 'Sales',
          data: [25, 20, 30, 22, 17, 29]
        }]
      }
    },
    issuedChart: {
      allData: [
        [32, 8, 3],
        [5, 3, 2],
      ],
      chartData: {
        labels: ['Green', 'Amber', 'Red'],
        datasets: [{
          label: 'Status',
          data: [32,8,3],
          backgroundColor: ['rgb(31,184,31)', 'rgb(210,134,40)', 'rgb(210,40,40)'],
          borderColor: 'rgb(3,23,59)',
          borderWidth: 1,
        }]
      },
      extraOptions: chartConfigs.pieChartOptions,
      activeIndex: 0
    },
    premiumsChart: {
      allData: [
        [5, 2, 1],
        [2, 1, 0],
      ],
      chartData: {
        labels: ['Green', 'Amber', 'Red'],
        datasets: [{
          label: 'Status',
          data: [5,2,1],
          backgroundColor: ['rgb(31,184,31)', 'rgb(210,134,40)', 'rgb(210,40,40)'],
          borderColor: 'rgb(3,23,59)',
          borderWidth: 1,
        }]
      },
      extraOptions: chartConfigs.pieChartOptions,
      activeIndex: 0
    },

    filter: 'All Local Policies',
    faSpinner,
    widgetIndex: 0,
    widgetCount: 2,
    selectedCountry: {
      type: String,
      default: '',
    },
    selectedProgramme: -1,
    handlerImageSize: 80,
  }),
  computed: {
    totalPremium() {
      return (this.programme.currencyCode ?? "USD") + " " + this.formatThousandsMixin(this.programme.premium ?? 0);
    },
    totalClaims() {
      return (this.programme.currencyCode ?? "USD") + " " + this.formatThousandsMixin(this.programme.claimsTotal ?? 0);
    },
    totalInquiries() {
      return this.programme.inquiryCount ?? 0;
    },
    timelinessCounts() {
      return [
        this.programme?.metricTimeliness?.S ?? 0,
        this.programme?.metricTimeliness?.G ?? 0,
        this.programme?.metricTimeliness?.A ?? 0,
        this.programme?.metricTimeliness?.R ?? 0
      ]
    },
    issuanceCounts() {
      return [
        this.programme?.metricIssuance?.S ?? 0,
        this.programme?.metricIssuance?.G ?? 0,
        this.programme?.metricIssuance?.A ?? 0,
        this.programme?.metricIssuance?.R ?? 0
      ]
    },
    premiumReceiptCounts() {
      return [
        this.programme?.metricPremium?.S ?? 0,
        this.programme?.metricPremium?.G ?? 0,
        this.programme?.metricPremium?.A ?? 0,
        this.programme?.metricPremium?.R ?? 0
      ]
    },
    iCedeClientName() {
      return process.env.VUE_APP_ICEDE_CLIENT;
    },

    programme: function() {
      const prog  = this.$store.getters.programmes.find(prog => prog.id==this.programmeId);
      return prog ?? {};
    },
    programmeHandlers() {
      if (!this.programme?.handlers) {
        return [];
      }
      let handlers = [];
      if (this.programme.handlers.primaryHandler) {
        handlers.push(this.programme.handlers.primaryHandler);
      }
      if (this.programme.handlers.secondaryHandler) {
        handlers.push(this.programme.handlers.secondaryHandler);
      }
      // if (this.programme.handlers.primaryClaimsHandler) {
      //   handlers.push(this.programme.handlers.primaryClaimsHandler);
      // }
      // if (this.programme.handlers.secondaryClaimsHandler) {
      //   handlers.push(this.programme.handlers.secondaryClaimsHandler);
      // }
      return handlers;
    },
    localPolicyStats: function () {
      const lPols = this.localPolicies;
      const issuedPolicies = lPols.reduce((total, lpol) => (lpol.flag_issued == 1) ? total + 1 : total, 0);
      const grossPremium = this.programme.premium;
      const openClaims = 0; //lPols.reduce((total, lpol) => total + lpol.claims, 0);
      const claimsPayments = 0; //lPols.reduce((total, lpol) => total + lpol.claims_payments, 0);
      const openActions = lPols.reduce((total, lpol) => (lpol.flag_has_counter_quote==1) ? total + 1 : total, 0);

      return [
        {label: 'Local Policies', value: this.programme.lpolCount},
        {label: 'Policies Issued', value: issuedPolicies},
        {label: 'Gross Premium (USD)', value: grossPremium},
        {label: 'Open Claims', value: openClaims},
        {label: 'Claims Payments (USD)', value: claimsPayments},
        {label: 'Open Actions', value: openActions},
      ];
    },
    localPolicies() {
      // Annoyingly, the programme ID changes type between String and Number
      //  depending on where it is set, so we have to do a "loose equality" comparison here
      return this.$store.getters.localPoliciesForProgramme.filter(lPol => lPol.master_policy_id==this.programmeId);
    },
    filteredLocalPolicies() {
      switch (this.filter) {
        case this.FILTER_OUTSTANDING:
          return this.outstanding;
        case this.FILTER_COMPLETE:
          return this.instructionsComplete;
        case this.FILTER_SENT:
          return this.bindOrderSent;
        case this.FILTER_ISSUED:
          return this.issued;
        case this.FILTER_COLLECTED:
          return this.premiumsCollected;
        default:
          return this.localPolicies;
      }
    },
    heatmapData() {
      let data = [['Country', this.programme.currencyCode]]

      let countryObj = {};

      for (let localPolicy of this.localPolicies) {
        let total = 0;
        for (let line of localPolicy.lines) {
          total += parseFloat(line.dec_risk_premium);
        }

        if (countryObj[localPolicy.country.country_name]) {
          countryObj[localPolicy.country.country_name] += parseFloat(total);
        } else {
          countryObj[localPolicy.country.country_name] = parseFloat(total);
        }
      }

      for (let country in countryObj) {
        data.push([country, countryObj[country]]);
      }

      return data;
    },
    outstanding() {
      return this.localPolicies.filter(lpol => lpol.instructions_complete==0 && lpol.flag_full_instr_received==0);
    },
    instructionsComplete() {
      return this.localPolicies.filter(lpol => lpol.instructions_complete==1 && lpol.flag_full_instr_received==0);
    },
    bindOrderSent() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==0 && lpol.flag_full_instr_received==1);
    },
    issued() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==1 && lpol.flag_full_instr_received==1 && !this.premiumHasBeenCollected(lpol));
    },
    premiumsCollected() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==1 && lpol.flag_full_instr_received==1 && this.premiumHasBeenCollected(lpol));
    },
    FILTER_ALL() {
      return 'All Local Policies';
    },
    FILTER_OUTSTANDING() {
      return 'Instructions Outstanding';
    },
    FILTER_COMPLETE() {
      return 'Instructions Complete';
    },
    FILTER_SENT() {
      return 'Bind Order Sent';
    },
    FILTER_ISSUED() {
      return 'Local Policy Issued';
    },
    FILTER_COLLECTED() {
      return 'Local Premium Collected';
    }
  },
  methods: {
    changeWidgetIndex(x = 1) {
      const newWidgetIndex = this.widgetIndex + x;
      this.widgetIndex = (newWidgetIndex < 0)
          ? this.widgetCount - 1 : newWidgetIndex % this.widgetCount;
    },
    countrySelected(country) {
      this.selectedCountry = country;
    },
    isProgrammeHighlighted(programmeName) {
      if (this.selectedCountry==='') {
        return false;
      }
      let match = "Global";
      if (this.selectedCountry === 'Brazil' || this.selectedCountry === 'Russia') {
        match += "|Rest of World";
      } else if (this.selectedCountry === 'France' || this.selectedCountry === 'Germany') {
        match += "|European";
      } else if (this.selectedCountry === 'United States' || this.selectedCountry === 'Canada') {
        match += "|North America"
      }
      let regEx = new RegExp(match);
      return regEx.test(programmeName);
    },
    async loadProgrammes() {
      await this.$store.dispatch('getProgrammes');
      const programmes = this.$store.getters.programmes;
      // if (programmes.length) {
      //   this.selectedProgramme=programmes[0].id;
      // }
    },
    localPoliciesFetched: function(master_policy_id) {
      // const lpols = this.$store.getters.localPoliciesForProgramme.filter(lpol => lpol.master_policy_id==master_policy_id);
      // return lpols.length > 0;
    },
    getLocalPolicies(master_policy_id) {
      this.$store.dispatch('getLocalPolicies', { master_policy_id });
    },
    premiumHasBeenCollected(localPolicy) {
      return localPolicy.lines.some(line => line.flag_payment_made == 1);
    },
    initDonuts(index) {
      this.issuedChart.activeIndex = index;
      let issChartData = this.issuedChart.chartData;
      issChartData.datasets[0].data = this.issuedChart.allData[index];
      this.issuedChart.chartData = issChartData;

      this.issuedChart.activeIndex = index;
      let premChartData = this.issuedChart.chartData;
      premChartData.datasets[0].data = this.issuedChart.allData[index];
      this.issuedChart.chartData = premChartData;
    }
  },
  watch: {
    programmeId: async function(newId) {
      // This id watch function will be fired if the user changes the ID in the
      //  URL, clicks a link to the same view, or does an action that just reuses
      //  this component. When that happens, we need to check if a programme with
      //  the given ID exists, redirect if not.
      // For the above cases the beforeRouteEnter listener below is not fired.
      const programmeExists = await this.$store.dispatch('getProgramme', { programmeId: newId})
      if (!programmeExists) {
        this.$root.$bvToast.toast(
            'That programme is not available',
            {title: 'Programme Error', variant: 'danger', autoHideDelay:5000}
        );
        this.$router.replace({name: 'Dashboard'});
      } else {
        this.getLocalPolicies(newId);
      }
    }
  },
  // This listener is fired when the browser first arrives on this view from a
  //  a different route... It is also fired if a link is typed/pasted in to the
  //  overview for another programme ID.
  // The above watch id function is not fired before route enter.
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      const programmeExists = await vm.$store.dispatch('getProgramme', { programmeId: to.params.programmeId })
      if (!programmeExists) {
        vm.$root.$bvToast.toast(
            'That programme is not available',
            {title: 'Programme Error', variant: 'danger', autoHideDelay:5000}
        );
        next({name: 'Dashboard'})
      } else {
        vm.getLocalPolicies(to.params.programmeId);
        next();
      }
    });
  },
  mounted() {
    this.initDonuts(0);
    this.selectedCountry = '';
    this.loadProgrammes();
  }
};
</script>
<style scoped lang="scss">
$handlerImageSize: 60px;

table#handlersTable td img {
  width: $handlerImageSize;
  height: $handlerImageSize;
}

table#handlersTable td span, table#handlersTable td a {
  display: inline-block;
  margin-top: ($handlerImageSize * 0.35);
}

.badge-success {
  background-color: rgb(31,184,31);
  color: #eeffee;
}
.badge-warning {
  background-color: rgb(210,134,40);
  color: #fefeee;
}
.badge-danger {
  background-color: rgb(210,40,40);
  color: #feeeee;
}

.donut-row h4 {
  color: #ddddfa;
}

.badge {
  margin-top: 10px;
  margin-left: 5px;
}
</style>
