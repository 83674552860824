<template>
  <div class="stats-list">
    <div v-for="row in data" :key="row.label" class="row mt-3">
      <div class="col-5 text-right">
        <h5 class="align-middle ht-100p">{{ row.value | thousands }}</h5>
      </div>
      <div class="col-7 text-left">
        <h4>{{ row.label }}</h4>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'stats-list',
  props: {
    data: Array,
  }
};
</script>
<style scoped>
h5 {
  padding-top: 3px;
}
h4 {
  margin: 0;
  padding: 0;
}
</style>
