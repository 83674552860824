<template>
  <div>
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label><strong>Name</strong></label><br>
          {{ inquiry.name }}
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label><strong>Countries</strong></label><br>
          <div v-html="inquiry.countries"></div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label><strong>Class of Business</strong></label><br>
          {{ inquiry.classType.policy_class_type_caption }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InquiryOverview",
  props: {
    inquiry: {
      type: Object,
      required: true
    }
  }
};
</script>
