<template>
  <div class="policy-timeline mt-3">
    <!-- Start and end labels   -->
    <div class="row justify-content-between mb-1">
      <div class="col-2">
        <small>| Creation</small>
      </div>
      <div class="col-2">
        <small style="margin-left: -10px">Issuance |</small>
      </div>
    </div>
    <!--             Location pins with numbers   -->
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Instructions Outstanding"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ outstanding.length }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in outstanding" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">


            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Instructions Finalised"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ finalised.length }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in finalised" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">


            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Local Bind Order Sent"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ bindOrderSent.length }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in bindOrderSent" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Local Policies Issued"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ issued.length }}</span>
            </div>
          </div></div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in issued" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">


            <div
                class="loc-pin-container"
                v-b-tooltip.top.hover="{ customClass: 'pin-tooltip'}"
                title="Local Premiums Issued"
            >
              <i class="fa fa-4x fa-map-marker"></i>
              <span class="badge badge-circle badge-primary loc-pin-number">{{ premiumsCollected.length }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="row justify-content-center">
              <div v-for="lPol in premiumsCollected" :key="lPol.local_policy_id" class="col-1">
                <router-link
                    :to="{name: 'Local Policy Overview', params: {localPolicyId: lPol.local_policy_id, programmeId}}"
                    class="pol-pill"
                    :class="getLpolClass(lPol)"
                    v-b-tooltip.bottom.hover="{ title: lPol.country.country_name + ' - ID ' + lPol.local_policy_id, variant: 'primary' }"
                >
                  <i class="fa fa-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Arrows   -->
    <div class="row my-2">
      <div class="col">
        <div class="arrow-container">
          <div class="arrow">
            <div class="line text-center"><span class="h4">Central Management</span></div>
            <div class="point"></div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="arrow-container second">
          <div class="arrow">
            <div class="line text-center"><span class="h4">Local Handling</span></div>
            <div class="point"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Labels   -->
    <div class="row mt-4">
      <div class="col">
        <span class="timeline-pill bg-on-track"></span>
        <div style="display: inline-block; padding-left: 17px;">On Track</div>
      </div>
      <div class="col">
        <span class="timeline-pill bg-problems"></span>
        <div style="display: inline-block; padding-left: 17px;">Requires Attention</div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <span class="timeline-pill bg-active"></span>
        <div style="display: inline-block; padding-left: 17px;">Active</div>
      </div>
      <div class="col">
        <span class="timeline-pill bg-expired"></span>
        <div style="display: inline-block; padding-left: 17px;">Expired</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PolicyTimeline',
  props: {
    programmeId: {
      type: [Number, String],
      default: -1
    },
  },
  computed: {
    outstanding() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==0 && lpol.flag_full_instr_received==0);
    },
    finalised() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==0 && lpol.flag_full_instr_received==1);
    },
    bindOrderSent() {
      return this.localPolicies.filter(lpol => lpol.stage===3);
    },
    issued() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==1 && lpol.flag_full_instr_received==0);
    },
    premiumsCollected() {
      return this.localPolicies.filter(lpol => lpol.flag_issued==1 && lpol.flag_full_instr_received==1);
    },
    localPolicies() {
      // Annoyingly, the programme ID changes type between String and Number
      //  depending on where it is set, so we have to do a "loose equality" comparison here
      return this.$store.getters.localPoliciesForProgramme.filter(lPol => lPol.master_policy_id==this.programmeId);
    }
  },
  methods: {
    getLpolClass(lPol) {
      let cssClass = 'bg-on-track';
      if (lPol.date_expiry < Date.now()) {
        cssClass = 'bg-expired';
      } else if (lPol.flag_has_counter_quote == 1) {
        cssClass = 'bg-problems'
      } else if (lPol.flag_issued==1 && lPol.flag_full_instr_received==1) {
        cssClass = 'bg-active';
      }
      return cssClass;
    }
  }
};
</script>
<style lang="scss">
// Import iCede custom colours
@import "@clients/src/assets/scss/iCede.scss";
.policy-timeline {
  height: 290px;
}

// Failed pin drop shadow
//.fa-map-marker{filter:drop-shadow(0 8px 1px #888)}

.timeline-pill {
  display: inline-block;
  width: 16px;
  height:16px;
  border-radius: 20%;
}

.pol-pill {
  border-radius: 3px;
  display: inline-block;
  padding: 1px 3px;
  margin-left: 3px;
  font-size: 0.6em;
  line-height: 1.5;
}
.pol-pill:first-of-type {
  margin-left: 0;
}

.tooltip.b-tooltip.pin-tooltip {
  left: -10px;
}

.tooltip.b-tooltip.pin-tooltip .tooltip-inner {
  background-color: rgb(16, 48, 90);
}

.loc-pin-container {
  color: rgb(16, 48, 90);
  display: inline-block;
  width: 48px;
  overflow: visible;
  white-space: nowrap;
  cursor: pointer;
  margin-left: 12%;
}
@keyframes example {
  0%   {position:relative; top:0;}
  100% {position:relative; top:-5px;}
}
.loc-pin-container:hover {
  animation-name: example;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-out;
}

$pinLightenPc: 0%;
$pinColour: rgb(15,47,91); //#2155a0;
.policy-timeline .row:first-of-type {
  .col:nth-child(1) .loc-pin-container {
    color: $pinColour;
  }
  .col:nth-child(2) .loc-pin-container {
    color: lighten($pinColour, $pinLightenPc);
  }
  .col:nth-child(3) .loc-pin-container {
    color: lighten($pinColour, $pinLightenPc * 2);
  }
  .col:nth-child(4) .loc-pin-container {
    color: lighten($pinColour, $pinLightenPc * 3);
  }
  .col:nth-child(5) .loc-pin-container {
    color: lighten($pinColour, $pinLightenPc * 4);
  }
}

$textLightenPc: 0%;
.bg-on-track {
  background-color: $iCede-primary;
  color: lighten($iCede-primary, $textLightenPc);
}
.bg-problems {
  background-color: $iCede-warning;
  color: lighten($iCede-warning, $textLightenPc);
}
.bg-active {
  background-color: $iCede-success;
  color: lighten($iCede-success, $textLightenPc);
}
.bg-expired {
  background-color: $iCede-black;
  color: lighten($iCede-black, $textLightenPc);
}

.loc-pin-container.pin-issued {
  color: $iCede-success;
}

.loc-pin-number {
  position: relative;
  left: -30px;
  top: -25px;
  color: #f1f1f1;
  font-size: 1em;
  font-weight: normal;
}

.timeline-label {
  width: 30px;
  height: 80px;
  display: inline-block;
}
.timeline-label.first {
  margin-left: 0;
}

.timeline-label span {
  display: inline-block;
  position: relative;
  left: -65px;
  top: 85px;
  text-align: right;
  vertical-align: top;
  width: 180px;
  -ms-transform: rotate(-90deg); /* IE 9 */
  transform: rotate(-90deg);
  color: #525f7f;
}

.arrow-container {
  margin-top: 5px;
}

.arrow-container.second {
  margin-left: -19px;
}

.arrow-container .arrow {
  width:100%;
}
$arrowHeight: 20px;
$arrowTipWidth: 35px;
$arrowColour: $pinColour;
.line {
  margin-top:0;
  width: calc(100% - #{$arrowTipWidth});
  //background: rgb(85, 96, 124);
  border-bottom: 3px solid $arrowColour;
  padding-top: -4px;
  height: 4 * $arrowHeight/7;
  float:left;
}

.line span {
  background-color: white;
  padding: 0 5px;
}

.point {
  width: 0;
  height: 0;
  border-top: $arrowHeight/2 solid transparent;
  border-bottom: $arrowHeight/2 solid transparent;
  border-left: $arrowTipWidth solid $arrowColour;
  float:right;
}
$secondShorter: 22%;
.arrow-container.second .line {
  width: calc(100% - #{$arrowTipWidth} - #{$secondShorter});
}
.arrow-container.second .point {
  margin-right: $secondShorter;
}
</style>
