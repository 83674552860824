<template>
  <b-modal v-model="modalShowModel" title="Additional Information" hide-footer>
    <b-form @submit.prevent="submitForm">
      <b-form-group label="Additional Comments:">
        <b-form-textarea v-model="additionalInfo"></b-form-textarea>
      </b-form-group>

      <b-form-group label="Upload File:">
        <b-form-file
          v-model="selectedFile"
          :state="Boolean(selectedFile)"
          placeholder="Choose a file or drop it here..."
          :accept="acceptedFileTypes"
        ></b-form-file>
      </b-form-group>

      <hr />
      <br />

      <b-button variant="secondary" @click="hideModal()">Cancel</b-button>
      <b-button type="submit" variant="primary" class="float-right">
        Send to PO Handler
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import UnderwritingService from "@underwriters/src/services/underwriting.service";

export default {
  name: "AdditionalInfoModal",
  props: {
    inquiryId: {
      type: [Number, String]
    },
    modalShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      additionalInfo: "",
      selectedFile: null,
      acceptedFileTypes: process.env.VUE_APP_ACCEPTED_FILE_TYPES,
    };
  },
  methods: {
    submitForm() {
      UnderwritingService.sendAdditionalInfoToPOHandler(
        this.inquiryId,
        this.additionalInfo,
        this.selectedFile
      );

      this.hideModal();
    },
    hideModal() {
      this.$emit("hideModal");
    }
  },
  computed: {
    modalShowModel: {
      get () { return this.modalShow },
      set (value) { this.$emit('update:modalShow', value) },
    },
  }
};
</script>
