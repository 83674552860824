const ENDORSEMENT_FINISHED = 1;
const ENDORSEMENT_ABORTED = 3;

const PolicyMixin = {
    methods: {
        fieldExists(policy, field) {
            return policy!==undefined && Object.prototype.hasOwnProperty.call(policy, field);
        },
        isFinalised(localPolicy) {
            const fullInstrField = 'flag_full_instr_received';
            if (!this.fieldExists(localPolicy, fullInstrField)) {
                console.error("Policy didn't have required filed to check if it is finalised");
                return false;
            }
            return localPolicy[fullInstrField] == 1;
        },
        isCollected(localPolicy) {
            if (!this.fieldExists(localPolicy, 'lines') || localPolicy.lines.length === 0) {
                console.error("Policy doesn't have any lines when checking if collected");
                return false;
            }

            return localPolicy.lines.filter(line => this.lineIsActive(line) && line.flag_payment_made == 1).length > 0;
        },
        isIssued(localPolicy) {
            if (!this.fieldExists(localPolicy, 'flag_issued')) {
                console.error("Policy doesn't have the required filed to check if it is issued");
                return false;
            }
            return localPolicy.flag_issued==1;
        },
        hasActiveEndorsement(localPolicy) {
            if (!this.fieldExists(localPolicy, 'endorsements')) {
                console.error("Policy doesn't have the required filed to check if it is issued");
                return false;
            }
            return localPolicy.endorsements.filter(
                endorsement => endorsement.endorsement_status != ENDORSEMENT_FINISHED
                                && endorsement.endorsement_status != ENDORSEMENT_ABORTED
            ).length > 0;
        },
        getVariantFromRAGBand(RAG) {
            let variant = 'success';
            if (RAG === 'A') {
                variant = 'warning';
            } else if (RAG === 'R') {
                variant = 'danger';
            }
            return variant;
        },
        premiumReceiptStatus(lines) {
            const activeLines = this.filterToActiveLines(lines);
            activeLines.sort((a, b) => a - b);
            let line = activeLines.find(line => line.flag_payment_made==1);
            if (line === undefined) {
                line = activeLines[0];
            }
            if (!this.fieldExists(line, 'metric_premium_receipt')
                || line.metric_premium_receipt.length === 0
            ) {
                // if (process.env.NODE_ENV !== 'production') {
                //     console.error("couldn't find premium receipt metric on line for status");
                // }
                return;
            }

            const variant = this.getVariantFromRAGBand(line.metric_premium_receipt[0].current_rag_band);

            return {
                grade: line.metric_premium_receipt[0].current_rag_band,
                satisfied: line.metric_premium_receipt[0].flag_is_satisfied,
                date_satisfied: line.metric_premium_receipt[0].date_satisfied,
                date_completed: line.date_payment_made,
                variant
            }
        },
        policyIssuanceStatus(policy) {
            if (!this.fieldExists(policy, 'metric_policy_issuance')
                || policy.metric_policy_issuance.length === 0
            ) {
                // if (process.env.NODE_ENV !== 'production') {
                //     console.error("couldn't find policy issuance metric for status");
                // }
                return;
            }

            const variant = this.getVariantFromRAGBand(policy.metric_policy_issuance[0].current_rag_band);

            return {
                grade: policy.metric_policy_issuance[0].current_rag_band,
                satisfied: policy.metric_policy_issuance[0].flag_is_satisfied,
                date_satisfied: policy.metric_policy_issuance[0].date_satisfied,
                date_completed: policy.date_issued,
                variant
            }
        },
        completenessStatus(policy) {
            if (!this.fieldExists(policy, 'metric_completeness')
                || policy.metric_completeness.length === 0
            ) {
                // if (process.env.NODE_ENV !== 'production') {
                //     console.error("couldn't find completeness metric for status");
                // }
                return;
            }

            const variant = this.getVariantFromRAGBand(policy.metric_completeness[0].current_rag_band);

            return {
                grade: policy.metric_completeness[0].current_rag_band,
                satisfied: policy.metric_completeness[0].flag_is_satisfied,
                date_satisfied: policy.metric_completeness[0].date_satisfied,
                date_completed: policy.date_full_instr_received,
                variant
            }
        },
        timelinessStatus(policy) {
            if (!this.fieldExists(policy, 'metric_timeliness')
                || policy.metric_timeliness.length === 0
            ) {
                return;
            }

            const variant = this.getVariantFromRAGBand(policy.metric_timeliness[0].current_rag_band);

            return {
                grade: policy.metric_timeliness[0].current_rag_band,
                satisfied: policy.metric_timeliness[0].flag_is_satisfied,
                date_satisfied: policy.metric_timeliness[0].date_satisfied,
                date_completed: policy.date_full_instr_received,
                variant
            }
        },
        calculateTotalPremium(lines) {
            return lines.reduce(
                (runningTotal, line) => {
                    if (this.lineIsActive(line)) {
                        return runningTotal + parseFloat(line.dec_risk_premium);
                    }
                    return runningTotal;
                },
                0
            );
        },
        lineCollectionType(line) {
            if (!this.fieldExists(line, 'flag_central_collection_required')) {
                return '';
            }
            if (line.flag_central_collection_required == 1) {
                return 'Central Collection';
            } else {
                return 'Local Collection';
            }
        },
        filterToActiveLines(lines) {
            return lines.filter(line => this.lineIsActive(line));
        },
        lineIsActive(line) {
            return (line.flag_onlineps_draft==0
                && line.flag_onlineps_discarded==0
                && line.flag_reversed==0
                && line.reversed_local_policy_line_id==null
            );
        }
    }
}

export default PolicyMixin;
